<template>
  <div
    class="navigation-menu fixed top-0 left-0 right-0 max-md:h-[60px] h-[88px] md:pl-2 md:pr-4 md:py-3 z-[50] bg-blue-dark flex items-center justify-stretch"
    :class="{
      'z-[62]':
        showAuth ||
        showMenu ||
        showLanguageMenu ||
        showContactMenu ||
        showAuthMenu
    }"
  >
    <div class="min-[480px]:flex-1 h-full md:hidden">
      <AppMenu
        :items="menu.filter(i => i.showInMobile !== false)"
        class="!left-2 origin-top-left !top-[calc(100%+0.5rem)] !min-w-[220px] !z-[62]"
        container-class="h-full p-2"
      >
        <AppButton
          class="icon-button variant-invisible h-full aspect-square !p-0 text-white"
          :icon="mdiMenu"
          :aria-label="$t('common.menu')"
        />
      </AppMenu>
    </div>
    <div class="min-[480px]:flex-1 h-full max-md:hidden flex order-2">
      <template v-for="item in desktopMenu">
        <AppMenu
          v-if="!item.submenu && !item.to"
          :key="`menu-${item.text}`"
          :items="
            menu.filter(i => i.submenu === item.text && i.showInMobile !== true)
          "
          class="!left-2 origin-top-left !top-[calc(100%+0.5rem)] !min-w-[220px] !z-[62]"
          container-class="h-full"
          show-on-hover
          @update:open="e => (showMenu = e)"
        >
          <AppButton
            class="variant-invisible h-full !px-2 rounded text-white !font-normal whitespace-nowrap"
          >
            {{ item.text }}
          </AppButton>
        </AppMenu>
        <AppButton
          v-else-if="item.to"
          :key="`link-${item.text}`"
          class="variant-invisible h-full !px-2 rounded text-white !font-normal whitespace-nowrap"
          v-bind="item.external ? { href: item.to } : { to: item.to }"
        >
          {{ item.text }}
        </AppButton>
      </template>
    </div>
    <div class="h-full md:order-1 md:pl-4">
      <NuxtLink class="h-full rounded flex items-center" :to="localePath('/')">
        <NuxtImg
          class="h-3/4"
          :src="`${host}/images/pfs-logo.svg`"
          loading="lazy"
          alt="PFS Realty Group - Miami"
          provider="custom"
          width="180"
          height="48"
          format="webp"
          fit="scale-down"
        />
      </NuxtLink>
    </div>
    <div
      class="flex-1 h-full flex items-center justify-end max-md:pr-2 md:order-3"
    >
      <AppMenu
        v-if="enableLanguageMenu"
        class="!right-2 origin-top-right !top-[calc(100%+0.5rem)] !min-w-[220px] !z-[62]"
        container-class="h-full py-3 md:py-2 ml-auto md:mr-2 inherit-relative"
        :items="locales"
        @update:open="e => (showLanguageMenu = e)"
      >
        <AppButton
          class="icon-button variant-invisible h-full aspect-square !p-0 text-white"
        >
          <img
            :key="locale"
            class="h-3/4 w-3/4 object-cover"
            :src="`/images/languages/${locale}.svg`"
            alt="Switch language"
            width="48"
            height="48"
          />
        </AppButton>
        <template #menu-item="{ item }">
          <span class="flex gap-2 items-center">
            <img
              class="h-4 w-4 min-w-4 object-cover -ml-1"
              :src="`/images/languages/${item.text}.svg`"
              alt="Switch language"
              width="48"
              height="48"
            />
            <span>
              {{ t(`languages.${item.text}`) }}
            </span>
          </span>
        </template>
      </AppMenu>
      <AppMenu
        class="!right-2 origin-top-right !top-[calc(100%+0.5rem)] !min-w-[220px]"
        container-class="h-full py-2 md:mr-2 !z-[62] inherit-relative"
        :items="[
          { divider: true, text: 'Colombia' },
          {
            text: 'Bogotá: +57 (300) 8054226',
            to: 'tel:+57300-8054226'
          },
          {
            text: 'Medellin: +57 (31) 547-77733',
            to: 'tel:+5731-547-77733'
          },
          { divider: true, text: 'Ecuador' },
          {
            text: 'Guayaquil: +593 (98) 530-7558',
            to: 'tel:+593-98-530-7558'
          },
          { divider: true, text: 'México' },
          {
            text: 'CDMX: +52 (55) 2194-1431',
            to: 'tel:+5255-21941431'
          },
          { divider: true, text: 'USA' },
          {
            text: 'Miami: +1 (954) 536-9851',
            to: 'tel:+1954-536-9851'
          }
        ]"
        @update:open="e => (showContactMenu = e)"
      >
        <AppButton
          class="icon-button variant-invisible h-full aspect-square !p-0 text-white"
          :icon="mdiPhoneOutline"
          :aria-label="$t('common.contactMenu')"
        />
      </AppMenu>
      <slot>
        <SiteAuthButton @update:open="e => (showAuthMenu = e)" />
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiMenu, mdiPhoneOutline } from '@mdi/js';
import type { User } from '@nhost/vue';

import 'floating-vue/dist/style.css';

const AppMenu = defineAsyncComponent(
  () => import('@/async-components/App/Menu.vue')
);
const SiteAuthButton = defineAsyncComponent(
  () => import('@/async-components/Site/AuthButton.vue')
);

const {
  public: { enableLanguageMenu }
} = useRuntimeConfig();

const host = useHost(true);

const { t, locale, localeCodes } = useI18n();

const localePath = useLocalePath();

const { getSwitchedPath } = useAlternatePath();

function navigateToLocalePath(code: string) {
  let url = getSwitchedPath(code);

  if (url.includes('/blog')) {
    url = url.split('/blog')[0] + '/blog';
  }

  navigateTo(url, {
    external: url.startsWith('http') || url.includes('/blog')
  });
}

const user = inject('user', null) as Ref<User | null> | null;
const isAuthenticated = computed(() => Boolean(user?.value?.email));

const menu = computed(() => {
  return [
    {
      text: t('menu.home'),
      to: localePath('/'),
      showInMobile: false
    },
    {
      submenu: t('menu.search'),
      text: t('menu.newProperties'),
      to: localePath(`/${t('routes.projects')}`)
    },
    {
      submenu: t('menu.search'),
      text: t('menu.properties'),
      to: localePath(`/${t('routes.properties')}/${t('paramsAliases.RES')}`)
    },
    {
      submenu: t('menu.search'),
      text: t('menu.buildings'),
      to: localePath(
        `/${t('routes.properties')}/` +
          `${t('paramsAliases.RES')}/` +
          `${t('paramsAliases.apartment')}/` +
          `${t('paramsAliases.building')}-${t('paramsKeys.propertySubType')}`
      )
    },
    {
      submenu: t('menu.search'),
      text: t('menu.zone'),
      to: localePath(`/${t('routes.county')}`)
    },
    {
      submenu: t('menu.search'),
      text: t('menu.inListing'),
      to: localePath(
        `/${t('routes.properties')}/` +
          `${t('paramsAliases.RES')}/` +
          `${t('paramsKeys.inListing')}`
      )
    },
    {
      submenu: t('menu.services'),
      text: t('menu.financing'),
      to: localePath(t('menu.financingLink')),
      external: true
    },
    {
      submenu: t('menu.services'),
      text: t('menu.propertyManagement'),
      to: localePath(t('menu.propertyManagementLink')),
      external: true
    },
    {
      submenu: t('menu.services'),
      text: t('menu.accounting'),
      to: localePath(t('menu.accountingLink')),
      external: true
    },
    {
      submenu: t('menu.services'),
      text: t('menu.rentInDollars'),
      to: localePath(t('menu.rentInDollarsLink')),
      external: true
    },

    {
      text: t('menu.aboutUs'),
      to: localePath(t('menu.aboutUsLink')),
      external: true
    },
    {
      text: t('menu.blog'),
      to: localePath(`/${t('routes.blog')}`),
      external: true
    },
    {
      submenu: t('menu.resources'),
      text: t('calculator.title'),
      to: localePath(`/${t('routes.calculator')}`)
    },
    {
      submenu: t('menu.resources'),
      text: t('marketReport.title'),
      to: localePath(`/${t('routes.marketReport')}`)
    },
    {
      text: t('menu.contactUs'),
      to: localePath(t('menu.contactUsLink'))
    }
  ];
});

const locales = computed(() => {
  return (localeCodes.value as string[])
    .map(code => ({
      text: code,
      action: code !== locale.value ? () => navigateToLocalePath(code) : null
    }))
    .filter(o => o.action);
});

const desktopMenu = computed<
  {
    text: string;
    to?: string;
    submenu?: string;
    external?: boolean;
  }[]
>(() => {
  return menu.value.reduce(
    (acc, item) => {
      if (item.submenu && item.showInMobile !== true) {
        if (acc.find(i => i.text === item.submenu)) {
          return acc;
        }
        acc.push({
          text: item.submenu
        });
      } else if (item.showInMobile !== true) {
        acc.push(item);
      }
      return acc;
    },
    [] as {
      text: string;
      to?: string;
      submenu?: string;
      external?: boolean;
    }[]
  );
});

watch(isAuthenticated, () => {
  if (isAuthenticated.value) {
    // fixes modal popping up on mobile when signing out
    showAuth.value = false;
  }
});

const showAuth = ref(false);

const showMenu = ref(false); // readonly
const showLanguageMenu = ref(false); // readonly
const showContactMenu = ref(false); // readonly
const showAuthMenu = ref(false); // readonly

const enableAuth = inject('enableAuth', ref(false)) as Ref<boolean> | null;

onMounted(() => {
  if (!enableAuth.value) {
    const params = new URLSearchParams(window.location.search);
    const redirectType = params.get('type');

    if (
      ['passwordReset', 'signinPasswordless'].includes(redirectType) ||
      window.apolloClient
    ) {
      enableAuth.value = true;
    } else {
      setTimeout(() => {
        enableAuth.value = true;
      }, 6500);
    }
  }
});
</script>

<style lang="scss">
.navigation-menu:has([data-headlessui-state='open']) {
  z-index: 62;
}
</style>
