// import type { LocaleObject } from 'vue-i18n-routing';
import { useMainStore } from '@/store';

interface LocaleObject {
  code: string;
  domain: string;
}

export default function () {
  const { locale, locales } = useNuxtApp().$i18n;

  const store = useMainStore();

  const switchLocalePath = useSwitchLocalePath();

  const host = useHost();

  const alternateLocaleCode = locale.value === 'us' ? 'mx' : 'us';

  const alternateLocale = locales.value.find(l => {
    return typeof l === 'object'
      ? l?.code.startsWith(alternateLocaleCode)
      : false;
  }) as LocaleObject | null;

  let alternateDomain =
    'domain' in alternateLocale ? alternateLocale.domain : host;

  if (alternateDomain.endsWith('/')) {
    alternateDomain = alternateDomain.slice(0, -1);
  }

  if (!alternateDomain.startsWith('http') && host.startsWith('http')) {
    const schema = host.split('//')[0];
    alternateDomain = `${schema}//${alternateDomain}`;
  }

  return {
    alternateLocale,
    alternateLocaleCode,
    alternateDomain,
    getSwitchedPath: (code?: string) => {
      const localePath = useLocalePath();

      function switchLocalePathAlt(code: string) {
        const path = window.location.pathname;

        const pathWithoutLocale =
          path.replace(new RegExp(`^/${locale.value}`), '') || '/';

        const query = window.location.search;
        const hash = window.location.hash;
        return localePath(pathWithoutLocale, code) + query + hash;
      }

      let url =
        switchLocalePath(code || (locale.value === 'us' ? 'mx' : 'us')) ||
        switchLocalePathAlt(code || (locale.value === 'us' ? 'mx' : 'us'));

      const translationPath =
        store.translationsPaths[alternateLocaleCode] ||
        store.currentTranslationPath;

      if (store.currentPathToTranslate && translationPath) {
        url = url.replace(store.currentPathToTranslate, translationPath);
      }

      url = url.replace(':://', '://');

      if (
        !url.startsWith('/') &&
        !url.startsWith('http') &&
        process.client &&
        typeof window !== 'undefined'
      ) {
        let schema = window.location.protocol;

        if (!schema.startsWith('http')) {
          schema = 'https:';
        }

        schema = schema.split('//')[0];

        url = `${schema}//${url}`;
      }

      return url;
    }
  };
}
